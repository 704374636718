html {
  font-size: 0.75vmin;
}
*,
*::before,
*::after {
  font-family: inherit;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif;
  font-size: 62.5%;
}

html body {
  font-size: 1.6rem;
  margin: 0;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  background-image: linear-gradient(to bottom, #131313, #000);
  perspective: 45rem;
  transform-style: preserve-3d;
}

#root {
  height: 100vh;
  width: 100vw;
  transform-style: preserve-3d;
}
